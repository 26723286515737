import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';
import CategoryPolygon from '../Components/CategoryPolygon';
import PathPrefix from '../Utils/PathPrefixUtil';
import * as Widget from '../Components/Widget';

class PolygonGroupTwoItemRow extends Component {
  render() {
    let { categories, style={}, navActions } = this.props;

    return (
      <PolygonWrapper style={style}>
        <Widget.Row
          extraCss={RwdResetTransCss}
        >
          <CategoryPolygon
            onClick={(item) => navActions.push(`/blog?cat=${item.id}`)}
            cat={this._getCatById(8)}
            extraCss={'transform: translate(10px, 0px);' + RwdResetTransCss}
          />
          <CategoryPolygon
            onClick={(item) => navActions.push(`/blog?cat=${item.id}`)}
              cat={this._getCatById(1)}
              extraCss={'transform: translate(-10px, 0px);' + RwdResetTransCss}
          />
        </Widget.Row>
      </PolygonWrapper>
    );
  }

  _getCatById = id => {
    let { categories } = this.props;
    if (id === 0) {
      return {
        display_name_tw: '',
        display_name_en: '',
        id: 0,
        image: PathPrefix('/images/pages/category/cat_0.jpg'),
      };
    }
    return categories.find(c => c.id === id);
  };
}

let PolygonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 700px) {
    & > div {
      flex-direction: column;
    }
  }
`;

let RwdResetTransCss = css`
  @media screen and (max-width: 700px) {
    transform: translate(0, 0);
    margin-bottom: 10px;
  }
`;

export default connect(
  (state, ownProps) => ({
    categories: Selectors.getChigiBlogCategories(state),
  }),
  ActionCreator
)(PolygonGroupTwoItemRow);
