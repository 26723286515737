import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../Page";
import ActionCreator from "../ActionCreator";
import * as Widget from "../Components/Widget";
import BlogItem from "../Components/BlogItem";
import PathPrefix from "../Utils/PathPrefixUtil";
import Selectors from "../Selectors";
import Banner from "../Components/Banner";
import * as L from "../Utils/Lang";
import PolygonGroupTwoItemRow from "../Components/PolygonGroupTwoItemRow";

const ITEMS_PER_PAGE = 12;
class BlogPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 0,
    };
  }

  render() {
    let { blogs, magazines, queryParams } = this.props;
    let { pageIndex } = this.state;

    let _showList =
      queryParams.cat &&
      (Number(queryParams.cat) === 1 || Number(queryParams.cat) === 8);
    let _items =
      queryParams.cat &&
      (Number(queryParams.cat) === 1 || Number(queryParams.cat) === 8)
        ? Number(queryParams.cat) === 1
          ? blogs
          : magazines
        : [];
    let _hasPrevPage = this._hasPrevPage(_items, pageIndex, ITEMS_PER_PAGE);
    let _hasNextPage = this._hasNextPage(_items, pageIndex, ITEMS_PER_PAGE);
    let _pages = new Array(Math.ceil(_items.length / ITEMS_PER_PAGE))
      .fill("")
      .map((_, idx) => idx + 1);

    return (
      <Wrapper>
        <Banner
          image={PathPrefix("/images/pages/blog/banner_blog.png")}
          text="BLOG"
        />
        {/* // workaround for unknow render issue for switch element between _showList */}
        <PolygonGroupTwoItemRow
          style={{
            marginTop: 60,
            marginBottom: 60,
            display: _showList ? "none" : "flex",
          }}
        />

        {_showList ? (
          <Widget.MaxWidth width={800}>
            {_items
              .slice()
              .sort((a, b) => new Date(b.created) - new Date(a.created))
              .slice(
                pageIndex * ITEMS_PER_PAGE,
                (pageIndex + 1) * ITEMS_PER_PAGE
              )
              .map((blog, idx) => (
                <BlogItem key={idx} item={blog} />
              ))}

            <div className="paging-actions">
              <Widget.FlatButton
                onClick={() => {
                  if (_hasPrevPage) {
                    window.scroll({ top: 320, behavior: "smooth" });
                    this.setState({ pageIndex: pageIndex - 1 });
                  }
                }}
                label={L.s("go-prev-page")}
                labelStyle={{ color: _hasPrevPage ? "#3b2946" : "#cecece" }}
                disabled={!_hasPrevPage}
              />

              <div className="page-numbers">
                {_pages.map((p, idx) => (
                  <div
                    key={idx}
                    className={`page-number${
                      idx === pageIndex ? " current" : ""
                    }`}
                    onClick={() => {
                      window.scroll({ top: 320, behavior: "smooth" });
                      this.setState({ pageIndex: idx });
                    }}
                  >
                    {p}
                  </div>
                ))}
              </div>

              <Widget.FlatButton
                onClick={() => {
                  if (_hasNextPage) {
                    window.scroll({ top: 320, behavior: "smooth" });
                    this.setState({ pageIndex: pageIndex + 1 });
                  }
                }}
                label={L.s("go-next-page")}
                labelStyle={{ color: _hasNextPage ? "#3b2946" : "#cecece" }}
                disabled={!_hasNextPage}
              />
            </div>
          </Widget.MaxWidth>
        ) : null}
      </Wrapper>
    );
  }

  _hasPrevPage = (items, curPage = 0, limit = 12) => {
    return curPage > 0;
  };

  _hasNextPage = (items, curPage = 0, limit = 12) => {
    return items.length > (curPage + 1) * limit;
  };
}

let Wrapper = styled.div`
  & .paging-actions {
    padding: 20px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & .page-numbers {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      & .page-number {
        padding: 5px;
        font-size: 14px;
        cursor: pointer;
      }

      & .page-number.current {
        text-decoration: underline;
      }
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      //blog id fix to 1
      blogs: Selectors.getItemsByCategoryId(state, 1),
      //magazine id fix to 8
      magazines: Selectors.getItemsByCategoryId(state, 8),
      queryParams: Selectors.getQueryParams(ownProps),
    }),
    ActionCreator
  )(BlogPage)
);
